import { Controller } from "stimulus"
import { modal } from "tingle.js"

export default class extends Controller {
  connect(){
    this.Modal = new modal();
  }

  open(event) {
    let url = this.element.dataset.url;

    fetch(url)
      .then(res => {
          return res.text();
      })
      .then(data => {
        this.Modal.setContent(data);
        this.Modal.open();
      });
  }
}