import appNoty from './app_noty'

const flashDocumentNoty = (document) => {
  document.addEventListener('DOMContentLoaded', () => {
    $('.js-noti').each(function (_, el) {
      const key = $(el).data('noti-key')
      const message = $(el).data('noti-message')

      appNoty(message, key).show()
    })
  })
}


export default flashDocumentNoty