import { Controller } from "stimulus"
import urlParser from 'js-video-url-parser/lib/base'
import 'js-video-url-parser/lib/provider/youtube'

export default class extends Controller {
  connect() {
      let linkTags = this.element.getElementsByTagName('a')
      Array.from(linkTags).forEach((linkTag) => {
        let videoInfo
        let href = linkTag.getAttribute('href')
        if (href) {
          videoInfo = urlParser.parse(href)
        }

        if (videoInfo) {
          let embedVideoUrl = urlParser.create({ videoInfo, format: 'embed' })
          linkTag.outerHTML = `
            <div class="post-video-container">
              <iframe src="${embedVideoUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>`
        } else {
          linkTag.setAttribute('target', '_blank')
        }
      });
  }
}