import "bootstrap"
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/js/all"

import Rails from "@rails/ujs"
import flashDocumentNoty from '../libs/flash_document_noty'

import "pages/common"
import "pages/faq"
import "pages/home"
import "controllers"

Rails.start()
flashDocumentNoty(document)