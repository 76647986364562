import { Controller } from "stimulus"
import appNoty from '../libs/app_noty'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardJS(this.element, {
      text: (trigger) => {
        return this.data.get('text')
      }
    })

    this.clipboard.on('success', function (event) {
      appNoty('Copied!', 'success').show()
      event.clearSelection()
    })

    this.clipboard.on('error', function (event) {
      appNoty('Press Ctrl+C to copy.', 'success').show()
      event.clearSelection()
    });
  }

  disconnect() {
    this.clipboard.destroy()
  }
}