import { Controller } from "stimulus"
import Swiper from '../libs/swiper.min';

export default class extends Controller {
  connect() {
    this.swiper = new Swiper('.swiper-container', {
      autoHeight: true,
      spaceBetween: 0,
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }

  disconnect() {
    this.swiper.destroy()
  }
}