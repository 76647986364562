import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['iframeWrapper', 'player' ]
  play(event) {
    event.preventDefault()

    this.iframeWrapperTarget.style.display = 'block'
    let url = this.data.get('url')
    if (url) {
      this.playerTarget.setAttribute('src', url + "?autoplay=1")
    }
  }

  close(event) {
    event.preventDefault()

    this.iframeWrapperTarget.style.display = 'none'
    this.playerTarget.setAttribute('src', '')
  }
}