import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['menu']

  connect() {
    window.addEventListener("click", this.handleClick.bind(this));
  }

  disconnect() {
    window.removeEventListener("click", this.handleClick.bind(this));
  }

  toggle(event) {
    this.menuTarget.classList.toggle('show')
  }

  open(event) {
    this.menuTarget.classList.add('show')
  }

  clickOutside(event) {
    this.menuTarget.classList.remove('show')
  }

  handleClick(event) {
    let targetElement = event.target // clicked element
    do {
      if (targetElement == this.element) {
        // This is a click inside, does nothing, just return.
        return
      }
      targetElement = targetElement.parentNode
    } while (targetElement)

    this.clickOutside(event)
  }
}