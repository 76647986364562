import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['overlay', 'iframe']
  connect() {
  }

  disconnect() {
  }

  open(event) {
    if (event.target.tagName.toLowerCase() === 'a' && event.target.href) {
      return
    }

    let url = this.data.get('url')
    if (!url) { return }

    event.preventDefault()
    event.stopPropagation()

    this.overlayTarget.style.display = 'block'
    this.iframeTarget.setAttribute('src', url + '?autoplay=1')
  }

  close(event) {
    event.preventDefault()
    event.stopPropagation()

    this.overlayTarget.style.display = 'none'
    this.iframeTarget.setAttribute('src', '')
  }
}
