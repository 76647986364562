import { Controller } from "stimulus"

// Import TinyMCE
import 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import contentStyle from '!!raw-loader!tinymce/skins/ui/oxide/content.css';
import contentStyle2 from '!!raw-loader!tinymce/skins/content/default/content.css';

export default class extends Controller {
  static targets = ["field"]

  connect() {
    let contentCss = document.getElementsByTagName('body')[0].dataset.editorContentCss

    tinymce.init({
      target: this.fieldTarget,
      height: 500,
      branding: false,
      menubar: false,
      body_class: 'trix-content',
      language_url: '/tinymce/langs/ko_KR.js',
      language: 'ko_KR',
      valid_elements: '*[*]',
      content_css: contentCss,
      skin_url: '/tinymce/skins/ui/oxide',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen code media',
        'insertdatetime media table paste code help wordcount hr',
      ],
      toolbar: 'undo redo | formatselect | ' +
        'bold italic backcolor underline | ' +
        'alignleft aligncenter  alignright alignjustify | ' +
        'bullist numlist outdent indent | ' +
        'link image media code hr | ' +
        'removeformat',
      automatic_uploads: true,
      convert_urls: false,
      images_upload_url: '/upload/image',
      block_unsupported_drop: false,
      file_picker_types: 'image media',
      images_upload_credentials: true,
      target_list: [
        { title: '현재 페이지', value: '' },
        { title: '새 창', value: '_blank' },
      ],
    });
  }
}
