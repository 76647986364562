import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "panel" ]

  open(event) {
    this.panelTarget.classList.add('open')
    setTimeout(() => { document.body.style.overflowY = "hidden" }, 400)
  }

  close(event) {
    this.panelTarget.classList.remove('open')
    document.body.style.overflowY = "scroll"
  }
}