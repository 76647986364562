import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { load } from 'recaptcha-v3'

import flashHeaderNoty from '../libs/flash_header_noty'

export default class extends Controller {
  static targets = ['result', 'recaptchaField']

  async readyRecaptcha(event) {
    if (this.hasRecaptchaFieldTarget && !this.recaptchaFieldTarget.value) {
      event.preventDefault()

      const recaptcha = await load(this.data.get('siteKey'))
      const token = await recaptcha.execute(this.data.get('action'))
      this.recaptchaFieldTarget.value = token

      Rails.fire(this.element, "submit")
    }
  }

  complete(event) {
    let [xhr] = event.detail

    flashHeaderNoty(xhr.getResponseHeader('X-Flash-Messages'), xhr.status)

    if (xhr.status == 200 && xhr.response) {
      this.resultTarget.innerHTML = xhr.response
    }
    if (this.hasRecaptchaFieldTarget) {
      this.recaptchaFieldTarget.value = ''
    }
  }
}